<template>
  <div>
    <k-header-card class="pb-0">
      <div class="d-flex align-items-center">
        <div
          class="w-100 d-flex justify-content-between header-with-save-button"
        >
          <div class="w-50 d-flex flex-column align-items-start">
            <span
              class="
                text-primary
                d-flex
                align-items-center
                font-work-sans
                cursor-pointer
              "
              @click="routeToPreviousPage"
            >
              <feather-icon
                class="me-1"
                icon="ChevronLeftIcon"
              /> Back To {{ previousRouteText }}
            </span>
            <span
              v-if="shipment"
              class="kp-text-color font-inter kp-id"
            >
              Shipment ID:
              <b-dropdown
                v-if="shipment && shipment.shipmentSeqId"
                variant="none"
                class="shipment-list-dropdown"
                no-caret
              >
                <template #button-content> {{ shipment.shipmentSeqId }} <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                /> </template>
                <div class="list-heading"> Select shipment </div>
                <b-dropdown-item
                  v-for="shipments in shipmentList"
                  :key="shipments._id"
                  :active="shipment._id === shipments._id"
                  :disabled="shipment._id === shipments._id"
                  :to="{name: $route.name, params: {shipmentId : shipments._id}}"
                >{{ shipments.shipmentSeqId }}</b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
          <div
            v-if="shipment._id"
            class="w-50 d-flex justify-content-end align-items-center"
          >
            <div v-if="isShipmentDetailsTab">
              <k-button
                variant="info"
                :disabled="isSaving"
                @click="saveChanges"
              >
                <loader-icon v-if="isSaving" />
                <save-icon
                  v-else
                  class="me-2"
                />
                SAVE CHANGES
              </k-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="navBarItems"
        class="d-flex tabs"
      >
        <span class="font-inter tab-header">Shipment Management Step: </span>
        <b-link
          v-for="navItem in navBarItems"
          :key="navItem.text"
          class="tab-item d-flex flex-row font-inter"
          :active="navItem.isActive"
          :disabled="navItem.isActive"
          @click="onClickTab(navItem)"
        >
          <span
            class="p-1 me-1"
            v-html="navItem.icon"
          />
          <span>{{ navItem.text }}</span>
        </b-link>
      </div>
    </k-header-card>

    <div class="kp-body-container">
      <router-view
        @on-updated="onSaved"
      />
    </div>
  </div>
</template>

<script>
import KButton from '@kingpin-global/kingpin-ui/src/components/button/Button.vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import SaveIcon from '@/assets/images/svg/SaveIcon.vue'
import shipmentManagementTabs from '@/assets/app-content/shipment-management-tabs.json'
import { LoaderIcon } from 'vue-feather-icons'
import { mapState } from 'vuex'
import { SET_IS_SHIPMENT_PAGE } from '@/store/modules/product.module'
import { BDropdown, BDropdownItem, VBModal } from 'bootstrap-vue'
import { CLEAR_EDITING_SHIPMENT, FETCH_SHIPMENTS } from '@/store/modules/shipment.module'
import store from '@/store'
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import { ROUTES } from '@/constants'

export default {
  name: 'ShipmentManagement',
  components: {
    FeatherIcon,
    SaveIcon,
    KButton,
    LoaderIcon,
    BDropdown,
    BDropdownItem,
    KHeaderCard,
  },
  directives: {
    'v-b-modal': VBModal,
  },
  data() {
    return {
      subNavBarItems: shipmentManagementTabs.subNavBarItems,
      navBarItems: null,
      shipmentId: this.$route.params.shipmentId,
      isSaving: false,
      shipmentList: [],
      prevRoute: '',
      previousRouteText: 'Shipments',
    }
  },
  computed: {
    ...mapState({
      shipment: state => state.shipments.editingShipment,
    }),
    isShipmentDetailsTab() {
      return this.$route.name === 'shipment-details-tab'
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
      vm.getPreviousRoute(from)
    })
  },
  watch: {
    shipment() {
      if (this.shipment) {
        const params = {
          orderId: this.shipment.orderId,
          sortBy: 'shipmentSeqId',
          asc: true,
          isMinimalData: true,
        }
        store.dispatch(FETCH_SHIPMENTS, params).then(res => {
          this.shipmentList = res.data.data
        })
      }
    },
  },
  created() {
    this.$store.commit(SET_IS_SHIPMENT_PAGE, true)
    this.navBarItems = this.subNavBarItems.map(navBarItem => ({
      ...navBarItem,
      isActive: this.$route.name === navBarItem.router.name,
    }))
  },
  destroyed() {
    this.$store.commit(SET_IS_SHIPMENT_PAGE, false)
    this.$store.commit(CLEAR_EDITING_SHIPMENT)
  },
  methods: {
    onClickTab(navItem) {
      this.navBarItems.forEach(navBarItem => {
        navBarItem.isActive = navItem.text === navBarItem.text
      })
      this.$router.push(navItem.router)
    },
    saveChanges() {
      this.isSaving = true
      this.$root.$refs.ShipmentDetailsTab.updateShipment()
    },
    onSaved() {
      this.isSaving = false
    },
    getPreviousRoute() {
      if ([ROUTES.NOTES.CHILDREN.CREDIT.name, ROUTES.NOTES.CHILDREN.DEBIT.name].includes(this.prevRoute?.name)) {
        this.previousRouteText = 'Credit/Debit Notes'
      }
      else {
        this.previousRouteText = 'Shipments'
      }
    },
    routeToPreviousPage() {
      if (this.prevRoute?.name) {
        this.$router.push({ name: this.prevRoute?.name })
      }
      else {
        this.$router.push({ path: `/${ROUTES.SHIPMENTS.path}` })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-management.scss";
</style>
